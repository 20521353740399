<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <!--<span class="mr-20">创建人：{{form.createUserName}}</span>-->
        <!--<span class="mr-20">创建时间：{{form.createTime}}</span>-->
        <!--<span class="mr-20">最后编辑人：{{form.createUserName}}</span>-->
        <!--<span class="mr-20">最后编辑时间：{{form.createTime}}</span>-->
      </div>
      <div slot="content">
        <a-card title="规则信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆类型" prop="kartClassifyId">
                  <a-select v-model="form.kartClassifyId" allowClear placeholder="请选择" v-if="!paramsId">
                    <a-select-option v-for="item in vehicleCategoryList" :key="item.kartClassifyId" :value="item.kartClassifyId">
                      {{item.kartClassifyName}}
                    </a-select-option>
                  </a-select>
                  <span v-else>
                  {{form.kartClassifyName}}
                </span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆规格" prop="kartClassifySpec">
                  <span>
                  {{form.kartClassifySpec}}
                </span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="按圈数计费" prop="quantity">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.quantity" :min="1" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="4">圈</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="门市价：" prop="quantityPrice">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.quantityPrice" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">RM</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="会员价：" prop="memberQuantityPrice">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.memberQuantityPrice" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">RM</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="按时间计费" prop="time">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.time" :min="1" step="0.1" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="4">分钟</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="门市价：" prop="timePrice">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.timePrice" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">RM</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="会员价：" prop="memberTimePrice">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.memberTimePrice" :min="0" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="2">RM</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="折上折" prop="discount">
                  <a-select v-model="form.discount" allowClear :disabled="isDetail">
                    <a-select-option :value="1" >
                      10折
                    </a-select-option>
                    <a-select-option :value="0.9">
                      9折
                    </a-select-option>
                    <a-select-option :value="0.8">
                      8折
                    </a-select-option>
                    <a-select-option :value="0.7">
                      7折
                    </a-select-option>
                    <a-select-option :value="0.6">
                      6折
                    </a-select-option>
                    <a-select-option :value="0.5">
                      5折
                    </a-select-option>
                    <a-select-option :value="0.4">
                      4折
                    </a-select-option>
                    <a-select-option :value="0.3">
                      3折
                    </a-select-option>
                    <a-select-option :value="0.2">
                      2折
                    </a-select-option>
                    <a-select-option :value="0.1">
                      1折
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注" prop="remarks">
                  <a-input v-model="form.remarks" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { vehicleAllCateGoryList, vehicleRuleInfo, vehicleRuleSubmit } from '@/api/vehicle';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'PriceRuleForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  props: {},
  data() {
    return {
      vehicleCategoryList: [],
      form: {
        kartClassifyId: null,
        quantity: 3,
        quantityPrice: 99,
        memberQuantityPrice: 99,
        time: 5,
        timePrice: 99,
        memberTimePrice: 99,
        discount: 1,
        remarks: ''
      },
      rules: {
        kartClassifyId: [
          {
            required: true,
            message: '请选择车辆类型'
          }
        ],
        quantity: [
          {
            required: true,
            message: '请正确填写按圈数计费信息',
          },
          // {
          //   validator: (rule, value, callback) => {
          //     if (!this.form.quantity || !this.form.quantityPrice) {
          //       callback(new Error('请正确填写按圈数计费信息'));
          //     }
          //     callback();
          //   },
          // }
        ],
        quantityPrice: [
          {
            required: true,
            message: '请正确填写按圈数计费信息',
          },
          // {
          //   validator: (rule, value, callback) => {
          //     if (!this.form.quantity || !this.form.quantityPrice) {
          //       callback(new Error('请正确填写按圈数计费信息'));
          //     }
          //     callback();
          //   },
          // }
        ],
        memberQuantityPrice: [
          {
            required: true,
            message: '请正确填写按圈计费会员价信息',
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.quantityPrice < this.form.memberQuantityPrice) {
                callback(new Error('会员价不能高于门市价'));
              }
              callback();
            },
          }
        ],
        time: [
          {
            required: true,
            message: '请正确填写按时间计费信息',
          },
        ],
        timePrice: [
          {
            required: true,
            message: '请正确填写按时间计费信息',
          },
        ],
        memberTimePrice: [
          {
            required: true,
            message: '请正确填写按时间计费会员价信息',
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.timePrice < this.form.memberTimePrice) {
                callback(new Error('会员价不能高于门市价'));
              }
              callback();
            },
          }
        ]
      },
    };
  },
  computed: {
  },
  watch: {},
  created() {
    this.getVehicleCategoryList();
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 车辆类型列表
    getVehicleCategoryList() {
      vehicleAllCateGoryList().then(({list}) => {
        this.vehicleCategoryList = list;
      });
    },

    // 信息
    getData(id) {
      vehicleRuleInfo(id).then((data) => {
        this.form = data;
      });
    },

    // 保存
    handleSave() {
      const params = { ...this.form };
      vehicleRuleSubmit(params).then((data) => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },

  }
};
</script>

<style lang="less" scoped>

</style>
